.reviews {
  padding: 125px 0;
  background-image: url(../images/reviews-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  @include media("<=550px") {}

 
}

.reviews__inner {
  width: 100%;
   
    gap: 20px;
    @include media("<=780px") {
    
    }
}

.reviews__item {

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 1.1;
  gap: 32px;
  color: #fff;
  @include media("<=780px") {
  
  }
}

.reviews__text {
  line-height: 1.5;
}

.reviews__title {
  font-weight: 600;
  font-size: 16px;
  gap: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews__image {
  width: 100%;
  display: block;
}