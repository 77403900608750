.features {
  position: relative;
  padding: 40px 0;
  overflow: hidden;
  
  @include media("<=1440px") {

  }
 
}

.features__inner {
  
  display: flex;
  flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    padding-top: 30px;
    padding-bottom: 30px;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
          gap: 16px;
        }
                @include media("<=810px") {
                   flex-direction: column;
                   align-items: center;
                }
        @include media("<=500px") {
          
        }
        &-column {
          padding-top: 40px;
          flex-wrap: nowrap;
        }
}

.features__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  padding: 20px;
  flex: 0 1 32%;
  border-radius: 30px;
    border: 1px solid #F74C13;
  text-align: left;
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=810px") {
     flex: 0 1 100%;
     max-width: 450px;
     width: 100%;
  }
  @include media("<=450px") {
    padding: 24px;
  }
 @include media("<=360px") {
   

 }
  &-big {
    flex: 0 1 48%;
  }

}


.features__title {
  text-transform: uppercase;
  
  font-size: 24px;
  font-weight: 700;

  display: flex;
    align-items: center;
    justify-content: center;
 

@include media("<=1440px") {
  
}
  @include media("<=1280px") {
   
  }

  @include media("<=450px") {
    font-size: 20px;
  }
    @include media("<=380px") {
      
    }
}

.features__text {
  color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 130%;
 
@include media("<=1080px") {

  }
  @include media("<=810px") {
    font-size: 14px;

  }

  @include media("<=430px") {
    font-size: 12px;
  }
}


.features__list {
  font-size: 14px;
    font-weight: 300;
    line-height: 130%;
    list-style: disc;
    padding-left: 15px;
}

.features__image {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}
