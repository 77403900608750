.about {
    padding: 40px 0;
    @include media("<=500px") {
       
    }
    
}

.about__inner {
    width: 100%;
   
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
@include media("<=810px") {
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
   
}

.about__content {
   max-width: 488px;
width: 100%;
    flex: 0 1 100%;
    line-height: 2;
    @include media("<=810px") {
        max-width: 600px;
    }
}

.about__title {
    font-size: 16px;
    text-transform: uppercase;
}

.about__text {
    max-width: 1098px;
    font-size: 14px;
    padding: 20px 0 30px;
  
    p {
       
    }
}


.about__image {
    max-width: 488px;
    width: 100%;
    flex: 0 1 100%;
    @include media("<=810px") {
        max-width: 600px;
        width: 100%;
    }
    img {
        width: 100%;
        display: block;
    }
}

.about__column {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
}


