.header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 20px 0;
  @include media("<=1080px") {
    
  }
  @include media("<=840px") {
    padding: 20px 0;
  }
  @include media("<=430px") {
    
  }
 &-page {
  background: #262F24;
 }
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 30px;
  z-index: 30;
  @include media("<=1080px") {
  
      margin: 0 auto;
    }
  @include media("<=700px") {
    flex-direction: column;
    align-items: center;
   
  }
}

.header__nav {
  
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  @include media("<=1080px") {
    
  }

  @include media("<=700px") {
    flex-direction: column;
    align-items: center;
    width: 100%;

    &.active {
      display: flex;
      position: fixed;
      height: 100%;
      max-height: 700px;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #252525;
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding: 90px 20px 20px;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      opacity: 1;

      @include media("<=430px") {
        padding-bottom: 0;

      }

      .header__link {
        padding: 15px;

        @include media("<=430px") {
         
        }

        @include media("<=350px") {
          
        }
      }
    }
  }
}

.header__link {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #Fbfbfb;
  transition: all 0.3s ease;
  position: relative;
  padding: 12px 30px;
border-radius: 50px;
  border: 1px solid #FBFBFB;
  @include media("<=1080px") {
    
  }
  @include media("<=700px") {
     max-width: 360px;
     width: 100%;
     text-align: center;
  }

  &:hover {
    
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #F7EDE3;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.logo {
  display: block;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  z-index: 21;
  text-decoration: none;
  max-width: 208px;
  @include media("<=1440px") {
    
  }
    @include media("<=1080px") {
      
    }
  @include media("<=810px") {
   
    
  }
    @include media("<=430px") {
      
    }
  img {
    width: 100%;
    display: block;
  }
  
}

