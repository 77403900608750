body {
  margin: 0;
font-family: "Montserrat", serif;
background: #151515;
  color: #fbfbfb;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  
}

.section__title {
  text-transform: uppercase;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%; 
    @include media("<=810px") {
      font-size: 50px;
    }
        @include media("<=430px") {
          font-size: 30px;
        }
}

.section__text {
  padding: 30px 0 50px;
  max-width: 996px;
}

.section__image {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  @include media("<=810px") {
    height: 150px;
    position: relative;
  }
  img {
    width: 100%;
    display: block;
    @include media("<=810px") {
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }
}

.wrapper {
  
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=840px") {
    display: block;
    position: fixed;
    top: 26px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 26px;
    right: 20px;
  }

  @include media("<=840px") {
    &.active {
      position: fixed;
      top: 32px;
      right: 40px;
      z-index: 200;
    }

    @include media("<=430px") {
      &.active {
        top: 32px;
        right: 20px;
      }
    }
  }

  .line {
    height: 4px;
    width: 50px;
    border-radius: 4px;
    margin-bottom: 6px;
    background-color: #fff;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #fff;

      &.one {
        transform: rotate(45deg) ;
      }

      &.two {
        transform: rotate(-45deg)  translateY(-7px) translateX(7px);

        @include media("<=736px") {
          transform: rotate(-45deg)  translateY(-7px) translateX(7px);
        }
      }

      &.three {
        opacity: 0;
      }
    }
  }
}

.page {
  padding: 100px 0 80px;
  background-color: #fff;
  color: #000;
}

.page__inner {
  padding-top: 60px;
  line-height: 1.2;
  color: #000;
  p {
    padding-bottom: 20px;
  }
  strong {
    font-weight: 600;
    display: block;
    padding: 10px 0;
  }
}

.swiper {
 
}

.hero__slide {
  width: 100%;
  height: 100%;
  position: relative;
  @include media("<=810px") {
    height: 300px;
    border-radius: 30px;
    overflow: hidden;
  }
    @include media("<=500px") {
      height: 210px;
    }
  img {
    
    width: 100%;
    
    @include media("<=810px") {
        height: 100%;
         object-fit: cover;
        position: absolute;
       
    }
  }
}