.footer {
padding: 60px 0;
background: #252525;
    @include media("<=430px") {

    }
}

.footer__inner {
    justify-content: space-between;
    flex-direction: column;
        width: 100%;
        display: flex;
    gap: 20px;
    position: relative;
    @include media("<=700px") {
       align-items: center;
    }
    @include media("<=640px") {
        flex-direction: column;
      
        row-gap: 20px;
    }
}

.footer__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    border-bottom: 1px solid #fff;
    padding-bottom: 24px;
    @include media("<=810px") {
        flex-direction: column;
    }
}

.footer__nav {
    display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
@include media("<=810px") {
    
    
}
        @include media("<=700px") {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 10px;
                width: 100%;
                align-items: center;
            }
}

.footer__link {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}



.copyright {
  
    font-size: 15px;
    @include media("<=810px") {
       
    }
}

.footer__text {
    display: flex;
    flex-direction: column;
        justify-content: center;
     gap: 16px;
    @include media("<=810px") {
        text-align: center;
    }
}