.info {
   padding: 40px 0;
}

.info__inner {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
   gap: 20px;
@include media("<=810px") {
   flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
}
@include media("<=500px") {
    flex-direction: column;
    align-items: center;
}
}

.info__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;  
    gap: 15px;
    flex: 0 1 24%;
    border-radius: 24px;
        border: 1px solid #F74C13;
    line-height: 1.3;
       padding: 20px;
        @include media("<=810px") {
            flex: 0 1 46%;
        }
                 @include media("<=500px") {
                   flex: 0 1 100%;
                 }
}

.info__image {
    width: 100%;
    max-width: 40px;
    img {
        width: 100%;
    }
}



.info__title {
    font-size: 24px;
    font-weight: 700;
 
    @include media("<=1080px") {
font-size: 20px;
    }
}

.info__text {
    font-size: 14px;
    font-weight: 400;
 
    @include media("<=810px") {
        
    }
}

